import { useEffect, useState } from "react";
import { Alert, Button, ListGroup, ProgressBar } from "react-bootstrap";
import { useAppContext } from "../App/AppContext";
import { AppRoleAssigned, AppRoleNotAssigned } from "../App/AppRolesAssigned";
import { getUserAccountsByCategory } from "../AzAdAccess/graphService";
import { config, getCategory, getCategoryNames } from "../Config/config";
import { CsvHeaderAssignment } from "../UIElements/CsvHeaderAssignment";
import { DropzoneCsv } from "../UIElements/DropzoneCsv";
import { LogDisp } from "../UIElements/LogDisp";
import { Selector } from "../UIElements/Selector";
import { accountDiff } from "../Utils/accountDiff";
import { readCsvFile, saveToCsvFile } from "../Utils/csvUtils";
import { epochTimeMs, fileNameWithDate, localDateTimeString } from "../Utils/dateTimeUtils";
import { addLogEntry, LogEntryType } from "../Utils/logDb";


export const DiffAccounts = () => {

    const logTopic = 'Konten abgleichen';
    const app = useAppContext();
    const [selectedCategory, setSelectedCategory] = useState('');
    const [fields, setFields] = useState<string[]>();
    const [csvData, setCsvData] = useState<any[]>([]);
    const [csvFileName, setCsvFileName] = useState('');
    const [isActionOngoing, setIsActionOngoing] = useState(false);
    const [progressAction, setProgressAction] = useState(0);
    const [assignedFields, setAssignedFields] = useState<{ [name: string]: string }>({});
    const [action, setAction] = useState('');
    const [logDispStartTime, setLogDispStartTime] = useState(0);

    const cat = getCategory(selectedCategory);

    const updateProgress = (done: boolean, maxCount: number, count: number) => {
        setProgressAction(Math.round(100.0 * count / maxCount));
    }

    const doDiff = async () => {
        setIsActionOngoing(true);
        setLogDispStartTime(epochTimeMs());
        setProgressAction(0);
        addLogEntry(LogEntryType.Info, logTopic, `Der Abgleich wurde gestartet: ${localDateTimeString()}`);

        setAction(`Phase 1: Konten der Kategorie ${selectedCategory} laden ...`);
        // Phase 1: Download all accounts of this category
        const accounts = await getUserAccountsByCategory(app.authProvider!, selectedCategory, false, cat!.importSelect.join(','), updateProgress);
        if (accounts) {
            // Phase 2: Get diffs
            setAction(`Phase 2: Differenzen ermitteln ...`);
            const res = accountDiff(cat, accounts, csvData, assignedFields, updateProgress, logTopic, addLogEntry);
            let saveList: { fn: string, csv: Record<string, string>[] }[] = [];
            if (res.deleteAccounts.length > 0) saveList.push({
                fn: fileNameWithDate(cat?.exportName! + '_zu_löschen', 'csv'),
                csv: res.deleteAccounts
            });
            if (res.changedAccounts.length > 0) saveList.push({
                fn: fileNameWithDate(cat?.exportName! + '_zu_ändern', 'csv'),
                csv: res.changedAccounts
            });
            if (res.newAccounts.length > 0) saveList.push({
                fn: fileNameWithDate(cat?.exportName! + '_neu', 'csv'),
                csv: res.newAccounts
            });
            if (res.errorAccounts.length > 0) saveList.push({
                fn: fileNameWithDate(cat?.exportName! + '_mit_Fehler', 'csv'),
                csv: res.errorAccounts
            });

            if (saveList.length > 0) {
                for (const s of saveList) {
                    saveToCsvFile(s.fn, s.csv, config.csvDelimiter);
                    await new Promise(r => setTimeout(r, 1000)); // Without timeout Safari won't download multiple files
                }
            }
        } else {
            addLogEntry(LogEntryType.Error, logTopic, `Benutzerkonten können nicht geladen werden.`);
        }
        setIsActionOngoing(false);
        addLogEntry(LogEntryType.Info, logTopic, `Der Abgleich wurde beendet: ${localDateTimeString()}`);
    }

    useEffect(() => {
        setLogDispStartTime(epochTimeMs());
    }, []);

    return (
        app.user! &&
        <div className="page-margin page-maxwidth">
            <h3>Konten abgleichen</h3>
            <p><small><i>
                Hinweis: Es werden Differenzlisten generiert (neu, verändert, zu löschen). Bestehende Konten werden nicht verändert.'
            </i></small></p>
            <br />
            <AppRoleAssigned appRoles={app.appRoles!} requiredRole="access.read" >
                <ListGroup>
                    <ListGroup.Item className="border-0">
                        <Selector title="Kategorie auswählen ..." items={getCategoryNames()} onItemChanged={e => {
                            setSelectedCategory(e.currentTarget.value);
                        }} value={selectedCategory} />
                    </ListGroup.Item>
                    <ListGroup.Item className="border-0" hidden={selectedCategory.length === 0}>
                        <DropzoneCsv onDrop={(acceptedFiles) => {
                            setCsvFileName(acceptedFiles[0].name);
                            readCsvFile(acceptedFiles[0], (res) => {
                                setFields(res.meta.fields);
                                setCsvData(res.data);
                            });
                        }} />
                        {csvFileName && <p>CSV-Datei: {csvFileName}</p>}
                    </ListGroup.Item>
                    <ListGroup.Item className="border-0" hidden={selectedCategory.length === 0 || !fields || fields.length === 0}>
                        <div>
                            <CsvHeaderAssignment
                                availableFields={fields!}
                                requiredFields={cat?.importHeader!}
                                titleAvailableFields="Felder der importierten CSV-Datei:"
                                titleAssignment="Zugewiesene Felder:"
                                onAssignment={(assignment) => {
                                    setAssignedFields(assignment);
                                }} />
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item className="border-0" hidden={selectedCategory.length === 0 || !fields || fields.length === 0}>
                        <Button onClick={doDiff} disabled={isActionOngoing}>Abgleichen ...</Button>
                        <p />
                        <div hidden={action.length === 0} >
                            {action}
                            <ProgressBar now={progressAction} label={`${progressAction}%`} />
                            <p />
                        </div>
                        <div className="log-disp">
                            <LogDisp height="10rem" startTime={logDispStartTime} />
                        </div>
                    </ListGroup.Item>
                </ListGroup>
            </AppRoleAssigned>
            <AppRoleNotAssigned appRoles={app.appRoles!} requiredRole="access.read">
                <Alert variant="danger">
                    Sie besitzen nicht die notwendigen Rechte.
                </Alert>
            </AppRoleNotAssigned>
        </div>
    );
}

import { User } from "@microsoft/microsoft-graph-types";
import { useEffect, useState } from "react";
import { Alert, Button, FloatingLabel, ListGroup } from "react-bootstrap";
import { useAppContext } from "../App/AppContext";
import { AppRoleAssigned, AppRoleNotAssigned } from "../App/AppRolesAssigned";
import { deleteUser, findUser, getMemberOf, getUser, isUserExists } from "../AzAdAccess/graphService";
import { getCategoryNameBySpecialProps } from "../Config/config";
import { AutoCompleteInput } from "../UIElements/AutoCompleteInput";
import { ButtonProgress } from "../UIElements/ButtonProgress";
import { CreatedUserAccountDetails } from "../UIElements/CreatedUserAccountDetails";
import { LogDisp } from "../UIElements/LogDisp";
import { epochTimeMs, localDateTimeString } from "../Utils/dateTimeUtils";
import { getGroups } from "../Utils/getGroups";
import { addLogEntry, LogEntryType } from "../Utils/logDb";


export const DeleteAccount = () => {
    const app = useAppContext();
    const [logDispStartTime, setLogDispStartTime] = useState(0);
    const [accountCategory, setAccountCategory] = useState('');
    const [selectedAccount, setSelectedAccount] = useState<User>();
    const [assignedDefaultGroups, setAssignedDefaultGroups] = useState<{ name: string, id: string }[]>();
    const [deleteAccountInProgress, setDeleteAccountInProgress] = useState(false);
    const [accountSuccessfullyDeleted, setAccountSuccessfullyDeleted] = useState(false);
    const [actionDone, setActionDone] = useState(false);
    const [resetSearchBox, setResetSearchBox] = useState('');

    const logTopic = 'Konto löschen';
    const selectStr = 'userPrincipalName, jobTitle, department, surname, givenName, displayName, employeeId';

    useEffect(() => {
        setLogDispStartTime(epochTimeMs());
    }, []);

    const resetForm = () => {
        setAccountSuccessfullyDeleted(false);
        setAccountCategory('');
        setSelectedAccount(undefined);
        setLogDispStartTime(epochTimeMs());
        setActionDone(false);
    }

    const doAction = async () => {
        addLogEntry(LogEntryType.Info, logTopic, `Die Aktion wurde gestartet: ${localDateTimeString()}`);
        setDeleteAccountInProgress(true);

        const upn = selectedAccount?.userPrincipalName;
        if (upn) {
            const isUser = await isUserExists(app.authProvider!, 'userPrincipalName', upn);
            if (isUser) {
                const isDeleted = await deleteUser(app.authProvider!, upn);
                if (isDeleted) {
                    addLogEntry(LogEntryType.Success, logTopic, `Das Konto <em>${upn}</em> wurde erfolgreich gelöscht.`);
                    setAccountSuccessfullyDeleted(true);
                } else {
                    addLogEntry(LogEntryType.Error, logTopic, `Das Konto <em>${upn}</em> konnte nicht gelöscht werden.`);
                }

            } else {
                addLogEntry(LogEntryType.Warning, logTopic, `Das Konto <em>${upn}</em> exsitert nicht.`);
            }
        } else {
            addLogEntry(LogEntryType.Warning, logTopic, `Das Konto <em>${upn}</em> exsitert nicht.`);
        }
        setDeleteAccountInProgress(false);
        addLogEntry(LogEntryType.Info, logTopic, `Die Aktion wurde beendet: ${localDateTimeString()}`);
        setActionDone(true);
    }

    return (
        <div className="page-margin page-maxwidth">
            <h3>Konto löschen</h3>
            <br />
            <AppRoleAssigned appRoles={app.appRoles!} requiredRole="access.delete" >
                <ListGroup>
                    <ListGroup.Item className="border-0">
                        <FloatingLabel label="Name | UID | Klasse">
                            <AutoCompleteInput
                                isChangeValueOnOptionSelectOnly
                                defaultValue={undefined}
                                fetchOptionsDisplayMembers={['displayName', 'userPrincipalName', 'jobTitle', 'employeeId']}
                                fetchOptionsValueMember={'userPrincipalName'}
                                fetchOptions={(startsWith: string) => {
                                    return findUser(app.authProvider!, startsWith, 'displayName, employeeId, userPrincipalName, jobTitle');
                                }}
                                onValueChanged={async (foundUserUpn: string) => {
                                    if (foundUserUpn) {
                                        const user = await getUser(app.authProvider!, foundUserUpn, selectStr);
                                        if (user) {
                                            resetForm();
                                            setSelectedAccount(user);
                                            const grps = await getGroups(app.authProvider!, user?.userPrincipalName!);
                                            if (grps) setAssignedDefaultGroups(grps);
                                            const category = getCategoryNameBySpecialProps('jobTitle', user.jobTitle!);
                                            if (category) {
                                                setAccountCategory(category);
                                            }
                                        }
                                    }
                                }}
                                key={resetSearchBox} // to re-render the child component on "clear form" button press
                            />
                        </FloatingLabel>
                    </ListGroup.Item>
                    {selectedAccount &&
                        <>
                            <ListGroup.Item className="border-0">
                                <CreatedUserAccountDetails category={accountCategory} user={selectedAccount} groups={assignedDefaultGroups} />
                            </ListGroup.Item>
                            <ListGroup.Item className="border-0">
                                <div className="d-flex flex-row justify-content-between">
                                    <ButtonProgress className="mr-3" variant="primary"
                                        disabled={actionDone}
                                        buttonText="Konto löschen" isInProgress={deleteAccountInProgress}
                                        isDoneOk={accountSuccessfullyDeleted && actionDone}
                                        isDoneError={!accountSuccessfullyDeleted && actionDone}
                                        onClick={doAction}
                                    />
                                    <Button className="mr-3" variant="secondary" type="reset" onClick={() => {
                                        resetForm();
                                        setResetSearchBox(epochTimeMs().toString()); // any value that is different every time
                                    }}>
                                        Formular zurücksetzen
                                    </Button>
                                </div>
                                <p />
                                <div className="log-disp">
                                    <LogDisp height="10rem" startTime={logDispStartTime} />
                                </div>
                            </ListGroup.Item>
                        </>
                    }
                </ListGroup>
            </AppRoleAssigned>
            <AppRoleNotAssigned appRoles={app.appRoles!} requiredRole="access.delete">
                <Alert variant="danger">
                    Sie besitzen nicht die notwendigen Rechte.
                </Alert>
            </AppRoleNotAssigned>
        </div>
    )
}


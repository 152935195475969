import { Group } from "@microsoft/microsoft-graph-types";
import { useRef, useState } from "react";
import { Button, Card, Form, FormControl, Modal, Offcanvas, OffcanvasProps } from "react-bootstrap";
import { useAppContext } from "../App/AppContext";
import { findGroup } from "../AzAdAccess/graphService";

export interface OcFindGroupProps extends OffcanvasProps {
    onAdd?: (group: Group) => boolean
}

export const OcFindGroup = (props: OcFindGroupProps) => {
    const app = useAppContext();
    const lastOptionRequest = useRef('');
    const [groups, setGroups] = useState<Group[]>([]);
    const [showWarn, setShowWarn] = useState(false);

    const { onAdd, ...otherProps } = props;

    return (
        <Offcanvas onEnter={() => { setGroups([]) }} {...otherProps}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Gruppe hinzufügen</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Form>
                    <FormControl type="text" placeholder="Gruppenname"
                        onChange={(e) => {
                            const updOptions = async (startsWith: string) => {
                                const grps = await findGroup(app.authProvider!, startsWith);
                                setGroups(grps);
                            };
                            if (e.currentTarget.value && e.currentTarget.value.length > 0) {
                                lastOptionRequest.current = e.currentTarget.value;
                                updOptions(lastOptionRequest.current);
                            } else {
                                lastOptionRequest.current = '';
                                setGroups([]);
                            }
                        }}
                    />
                </Form>
                <>
                    {groups.map((grp: Group) => {
                        // only list MS365 or security groups (see https://docs.microsoft.com/en-us/graph/api/resources/groups-overview?view=graph-rest-1.0)
                        // do not list dynamic groups
                        if (grp.groupTypes && !grp.groupTypes.includes('DynamicMembership') &&
                                (grp.groupTypes.includes('Unified') || 
                                (!grp.groupTypes?.includes('Unfified') && !grp.mailEnabled && grp.securityEnabled))) {
                            return (
                                <>
                                    <p />
                                    <Card key={grp.id} body={false}>
                                        <Card.Header>
                                            <small>{grp.displayName}</small>
                                            <Button variant="link" size="sm" style={{ marginTop: "-2px", padding: 0, fontSize: "small", float: "right" }}
                                                onClick={() => {
                                                    if (onAdd) {
                                                        const res = onAdd(grp);
                                                        if (!res) {
                                                            setShowWarn(true);
                                                        }
                                                    }
                                                }}>
                                                hinzufügen
                                            </Button>
                                            <Card.Text>
                                                <small>
                                                    <sup>{grp.groupTypes.includes('Unified') ? '365-Gruppe' : 'Sicherheitsgruppe'}</sup>
                                                    <br />
                                                    {grp.description}
                                                </small>
                                            </Card.Text>
                                        </Card.Header>
                                    </Card>
                                </>
                            );
                        }
                        return null;
                    })}
                </>
                <Modal show={showWarn} onHide={() => setShowWarn(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Hinweis</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Diese Gruppe wurde bereits hinzufügt!</Modal.Body>
                </Modal>
            </Offcanvas.Body>
        </Offcanvas>
    );
}

import { AuthCodeMSALBrowserAuthenticationProvider } from "@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser";
import { getMemberOf } from "../AzAdAccess/graphService";

export const getGroups = async (authProvider: AuthCodeMSALBrowserAuthenticationProvider, upn: string, includeDynamicGroups: boolean = false) => {
    let grpRecords: { name: string, id: string }[] = [];
    const grps = await getMemberOf(authProvider, upn);
    if (grps) {
        for (const g of grps) {
            if (g.groupTypes) {
                const isDynGrp = g.groupTypes.includes('DynamicMembership');
                if (includeDynamicGroups || !isDynGrp) {
                    if (g.displayName && g.id) {
                        grpRecords.push({ name: g.displayName, id: g.id });
                    }
                }
            }
        }
    }
    return grpRecords;
}
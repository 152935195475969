import { User } from "@microsoft/microsoft-graph-types";
import { useEffect, useState } from "react";
import { ListGroup, Form, Button, FloatingLabel, Alert } from "react-bootstrap";
import { useAppContext } from "../App/AppContext";
import { AppRoleAssigned, AppRoleNotAssigned } from "../App/AppRolesAssigned";
import { addMemberToGroup, findUser, getUser, removeMemberFromGroup, updateUser } from "../AzAdAccess/graphService";
import { getCategory, getCategoryNameBySpecialProps, getCategoryNames, getCsvHeaderNameForExportSelectN, getJobTitleFromCategory } from "../Config/config";
import { AutoCompleteInput } from "../UIElements/AutoCompleteInput";
import { BasicUserData, BasicUserDataValues } from "../UIElements/BasicUserData";
import { ButtonProgress } from "../UIElements/ButtonProgress";
import { CreatedUserAccountDetails } from "../UIElements/CreatedUserAccountDetails";
import { LogDisp } from "../UIElements/LogDisp";
import { Selector } from "../UIElements/Selector";
import { makeSetUid, saveAccountToDb } from "../Utils/createdAccountDb";
import { epochTimeMs, localDateTimeString } from "../Utils/dateTimeUtils";
import { getGroups } from "../Utils/getGroups";
import { addLogEntry, LogEntryType } from "../Utils/logDb";
import { OcCreatedUserAccountDetails } from "./OcCreatedUserAccountDetails";


export const EditAccount = () => {

    const app = useAppContext();
    const [selectedAccount, setSelectedAccount] = useState<User>();
    const [selectedCategory, setSelectedCategory] = useState('');
    const [basicUserDataValues, setBasicUserDataValues] = useState<BasicUserDataValues>();
    const [logDispStartTime, setLogDispStartTime] = useState(0);
    const [changeAccountInProgress, setChangeAccountInProgress] = useState(false);
    const [validated, setValidated] = useState(false);
    const [changedUserAccount, setChangedUserAccount] = useState<User>();
    const [assignedGroups, setAssignedGroups] = useState<{ name: string, id: string }[]>();
    const [assignedDefaultGroups, setAssignedDefaultGroups] = useState<{ name: string, id: string }[]>();
    const [accountSuccessfullyChanged, setAccountSuccessfullyChanged] = useState(false);
    const [showChangedAccount, setShowChangedAccount] = useState(false);
    const [resetSearchBox, setResetSearchBox] = useState('');

    const logTopic = 'Konto bearbeiten';
    const cat = getCategory(selectedCategory);
    const selectStr = 'userPrincipalName, jobTitle, department, surname, givenName, displayName, employeeId';

    useEffect(() => {
        setLogDispStartTime(epochTimeMs());
    }, []);

    const updValue = (azUser: User, updUser: BasicUserDataValues | Record<string, string>[], updProps: Record<string, string | null>, prop: string) => {
        const azUserIdx = prop as keyof typeof azUser;
        const updUserIdx = prop as keyof typeof updUser;
        const newUpdProps = { ...updProps };

        const newVal: string = updUser[updUserIdx] || '';
        if (newVal.length > 0) {
            if (azUser[azUserIdx] !== newVal) {
                newUpdProps[prop] = newVal;
            }
        } else {
            newUpdProps[prop] = null;
        }
        return newUpdProps;
    }

    const updateGroupMembership = async (user: User, targetGrps: { name: string, id: string }[]) => {
        const currentGrps = await getGroups(app.authProvider!, user.userPrincipalName!);
        let grpsToAdd: { name: string, id: string }[] = [];
        let grpsToRemove: { name: string, id: string }[] = [];
        if (currentGrps) {
            for (const tg of targetGrps) {
                if (!currentGrps.some(e => e.id === tg.id)) {
                    grpsToAdd.push(tg);
                }
            }
            for (const cg of currentGrps) {
                if (!targetGrps.some(e => e.id === cg.id)) {
                    grpsToRemove.push(cg);
                }
            }
        } else {
            grpsToAdd = targetGrps;
        }

        // Add user to groups
        for (const g of grpsToAdd) {
            const rg = await addMemberToGroup(app.authProvider!, user.id!, g.id);
            if (!rg) {
                addLogEntry(LogEntryType.Warning, logTopic,
                    `Die Zuweisung zur Gruppe ${g.name} ist fehlgeschlagen`);
            } else {
                addLogEntry(LogEntryType.Success, logTopic,
                    `Neue Gruppe: ${g.name}`);
            }
        }

        // Remove user from groups
        for (const g of grpsToRemove) {
            const rg = await removeMemberFromGroup(app.authProvider!, user.id!, g.id);
            if (!rg) {
                addLogEntry(LogEntryType.Warning, logTopic,
                    `Das Entfernen von der Gruppe ${g.name} ist fehlgeschlagen`);
            } else {
                addLogEntry(LogEntryType.Success, logTopic,
                    `Entfernte Gruppe: ${g.name}`);
            }
        }
    }

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        addLogEntry(LogEntryType.Info, logTopic, `Die Aktion wurde gestartet: ${localDateTimeString()}`);
        try {
            setAccountSuccessfullyChanged(false);
            const form = e.currentTarget;
            e.preventDefault();

            if (form.checkValidity() === false) {
                e.stopPropagation();
            } else {
                const update = async (budv: BasicUserDataValues) => {
                    setChangeAccountInProgress(true);
                    const upn = budv.userPrincipalName!;
                    const azUser = await getUser(app.authProvider!, upn, selectStr);
                    if (azUser) {
                        addLogEntry(LogEntryType.Info, logTopic, `Das Konto ${azUser.userPrincipalName} wird aktualisiert.`);

                        let updProps: Record<string, string | null> = {};
                        updProps = updValue(azUser, budv!, updProps, 'givenName');
                        updProps = updValue(azUser, budv!, updProps, 'surname');
                        updProps = updValue(azUser, budv!, updProps, 'employeeId');
                        updProps = updValue(azUser, budv!, updProps, 'displayName');
                        updProps = updValue(azUser, budv!, updProps, 'department');
                        const jobTitle = getJobTitleFromCategory(cat!);
                        if (jobTitle) {
                            if (jobTitle !== azUser.jobTitle) {
                                updProps['jobTitle'] = jobTitle;
                                addLogEntry(LogEntryType.Success, logTopic, `Neue Kategorie: ${jobTitle}`);
                            }
                        }
                        if (!cat?.exportSelect.includes('department')) {
                            updProps['department'] = null;
                        }
                        const logUpdProperties: string[] = [];
                        for (const s in updProps) {
                            const pn = getCsvHeaderNameForExportSelectN(selectedCategory, s);
                            if (pn) {
                                logUpdProperties.push(pn);
                            } else {
                                logUpdProperties.push(s);
                            }
                        }
                        if (logUpdProperties.length > 0) {
                            addLogEntry(LogEntryType.Success, logTopic, `Aktualiserte Eigenschaften: ${logUpdProperties.join(', ')}`);
                        }
                        const updRes = await updateUser(app.authProvider!, upn, updProps);
                        if (updRes) {
                            const updatedUser = await getUser(app.authProvider!, upn, selectStr + ',id');
                            if (updatedUser && budv.groups) {
                                await updateGroupMembership(updatedUser, budv.groups);
                            }
                            setAccountSuccessfullyChanged(true);
                            setChangedUserAccount(updatedUser);
                            const resUserGroups = await getGroups(app.authProvider!, updatedUser!.userPrincipalName!);
                            setAssignedGroups(resUserGroups);
                            saveAccountToDb(makeSetUid(selectedCategory), updatedUser!);
                            setShowChangedAccount(true);

                            /* save to csv file - currently not used
                            const rowToSave: Record<string, string> = {};
                            rowToSave[getCsvHeaderNameForExportSelect(cat!, 'userPrincipalName')!] = updatedUser?.userPrincipalName || '';
                            rowToSave[getCsvHeaderNameForExportSelect(cat!, 'jobTitle')!] = updatedUser?.jobTitle || '';
                            rowToSave[getCsvHeaderNameForExportSelect(cat!, 'surname')!] = updatedUser?.surname || '';
                            rowToSave[getCsvHeaderNameForExportSelect(cat!, 'givenName')!] = updatedUser?.givenName || '';
                            rowToSave[getCsvHeaderNameForExportSelect(cat!, 'displayName')!] = updatedUser?.displayName || '';
                            rowToSave[getCsvHeaderNameForExportSelect(cat!, 'employeeId')!] = updatedUser?.employeeId || '';
                            if (getCsvHeaderNameForExportSelect(cat!, 'department')) {
                                rowToSave[getCsvHeaderNameForExportSelect(cat!, 'department')!] = updatedUser?.department || '';
                            }

                            for (let idx = 0; idx < budv!.groups!.length; ++idx) {
                                const grp = budv!.groups![idx];
                                rowToSave[`Gruppe ${idx + 1}`] = grp.name;
                            }

                            const fn = fileNameWithDate(`Aktualisierte_${cat?.exportName!}`, 'csv');
                            saveToCsvFile(fn, [rowToSave], config.csvDelimiter);
                            addLogEntry(LogEntryType.Info, logTopic, `Das aktualisierten Konten wurden gespeichert: ${fn}`);
                            */
                        } else {
                            setAccountSuccessfullyChanged(false);
                        }
                    } else {
                        setAccountSuccessfullyChanged(false);
                        addLogEntry(LogEntryType.Warning, logTopic, `Konto <b>${upn}</b> nicht in AzureAD vorhanden.`);
                    }

                    setChangeAccountInProgress(false);
                    setValidated(false);
                    addLogEntry(LogEntryType.Info, logTopic, `Die Aktion wurde beendet: ${localDateTimeString()}`);
                }
                if (basicUserDataValues) {
                    update(basicUserDataValues);
                }
            }
            setValidated(true);
        } catch { }
    }

    const resetForm = () => {
        setAccountSuccessfullyChanged(false);
        setSelectedCategory('');
        setSelectedAccount(undefined);
        setLogDispStartTime(epochTimeMs());
    }

    return (
        <div className="page-margin page-maxwidth">
            <h3>Konto bearbeiten</h3>
            <br />
            <AppRoleAssigned appRoles={app.appRoles!} requiredRole="access.write" >
                <ListGroup>
                    <ListGroup.Item className="border-0">
                        <FloatingLabel label="Name | UID | Klasse">
                            <AutoCompleteInput
                                isChangeValueOnOptionSelectOnly
                                defaultValue={undefined}
                                fetchOptionsDisplayMembers={['displayName', 'userPrincipalName', 'jobTitle', 'employeeId']}
                                fetchOptionsValueMember={'userPrincipalName'}
                                fetchOptions={(startsWith: string) => {
                                    return findUser(app.authProvider!, startsWith, 'displayName, employeeId, userPrincipalName, jobTitle');
                                }}
                                onValueChanged={async (foundUserUpn: string) => {
                                    if (foundUserUpn) {
                                        const user = await getUser(app.authProvider!, foundUserUpn, selectStr);
                                        if (user) {
                                            resetForm();
                                            setSelectedAccount(user);
                                            const selCat = getCategoryNameBySpecialProps('jobTitle', user?.jobTitle!);
                                            setSelectedCategory(selCat);
                                            const grps = await getGroups(app.authProvider!, user?.userPrincipalName!);
                                            if (grps) setAssignedDefaultGroups(grps);
                                        }
                                    }
                                }}
                                key={resetSearchBox} // to re-render the child component on "clear form" button press
                            />
                        </FloatingLabel>
                    </ListGroup.Item>
                    {selectedAccount &&
                        <ListGroup.Item className="border-0">
                            <CreatedUserAccountDetails category={selectedCategory} user={selectedAccount} groups={assignedDefaultGroups} />
                        </ListGroup.Item>
                    }
                    <ListGroup.Item className="border-0" hidden={selectedCategory.length === 0}>
                        <Selector title="Kategorie auswählen ..." items={getCategoryNames()} onItemChanged={e => {
                            setSelectedCategory(e.currentTarget.value);
                        }} value={selectedCategory} />
                        <Form noValidate validated={validated} onSubmit={onSubmit}>
                            {cat &&
                                <BasicUserData authProvider={app.authProvider!} category={selectedCategory}
                                    upnReadOnly
                                    surnameDef={selectedAccount?.surname || ''}
                                    givenNameDef={selectedAccount?.givenName || ''}
                                    employeeIdDef={selectedAccount?.employeeId || ''}
                                    displayNameDef={selectedAccount?.displayName || ''}
                                    userPrincipalNameDef={selectedAccount?.userPrincipalName || ''}
                                    departmentDef={selectedAccount?.department || ''}
                                    groupsDef={assignedDefaultGroups}
                                    onValueChanged={(value) => {
                                        setBasicUserDataValues(value);
                                    }}
                                    key={assignedDefaultGroups?.join(',')} // to re-render the child component on def group change
                                />
                            }
                            <p />
                            <div className="d-flex flex-row justify-content-between">
                                <Button className="mr-3" variant="success" hidden={!accountSuccessfullyChanged} onClick={e => setShowChangedAccount(true)} >
                                    Kontodetails anzeigen
                                </Button>
                                <ButtonProgress className="mr-3" variant="primary" type="submit" disabled={changeAccountInProgress}
                                    hidden={selectedCategory.length === 0 || accountSuccessfullyChanged}
                                    buttonText="Konto aktualisieren" isInProgress={changeAccountInProgress}
                                />
                                <Button className="mr-3" variant="secondary" type="reset" hidden={selectedCategory.length === 0} onClick={() => {
                                    resetForm();
                                    setResetSearchBox(epochTimeMs().toString()); // any value that is different every time
                                }}>
                                    Formular zurücksetzen
                                </Button>
                            </div>
                        </Form>
                        <p />
                        <div className="log-disp">
                            <LogDisp height="10rem" startTime={logDispStartTime} />
                        </div>
                    </ListGroup.Item>
                </ListGroup>
            </AppRoleAssigned>
            <AppRoleNotAssigned appRoles={app.appRoles!} requiredRole="access.write">
                <Alert variant="danger">
                    Sie besitzen nicht die notwendigen Rechte.
                </Alert>
            </AppRoleNotAssigned>
            <OcCreatedUserAccountDetails category={selectedCategory} user={changedUserAccount!} groups={assignedGroups}
                show={showChangedAccount} placement="end" onHide={() => setShowChangedAccount(false)} />
        </div>
    );
}

import { Client, GraphError, PageCollection, PageIterator, PageIteratorCallback } from '@microsoft/microsoft-graph-client';
import { AuthCodeMSALBrowserAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser';
import { Group, Organization, PasswordProfile, ServiceHealth, User } from "@microsoft/microsoft-graph-types";
import { config, getCategory } from "../Config/config";


let graphClient: Client | undefined = undefined;

const ensureClient = (authProvider: AuthCodeMSALBrowserAuthenticationProvider) => {
    if (!graphClient) {
        graphClient = Client.initWithMiddleware({
            authProvider: authProvider
        });
    }

    return graphClient;
}

export const getSignedInUser = async (authProvider: AuthCodeMSALBrowserAuthenticationProvider): Promise<User | undefined> => {
    ensureClient(authProvider);

    // Return the /me API endpoint result as a User object
    //
    const user: User = await graphClient!.api('/me')
        // Only retrieve the specific fields needed
        .select('displayName,surname, givenName, mail,userPrincipalName')
        .get();

    return user;
}

export const getSignedInUserPhotoUrl = async (authProvider: AuthCodeMSALBrowserAuthenticationProvider): Promise<string | undefined> => {
    ensureClient(authProvider);

    // Get the photo of the user
    //
    let photoUrl: string | undefined = undefined;
    try {
        const photo: Blob = await graphClient!.api('/me/photos/48x48/$value')
            .get();
        photoUrl = URL.createObjectURL(photo);
    } catch { }

    return photoUrl;
}

export const getGroup = async (authProvider: AuthCodeMSALBrowserAuthenticationProvider, id?: string, name?: string): Promise<Group | undefined> => {
    ensureClient(authProvider);

    // Return the group info
    //
    let grp: Group | undefined = undefined;
    try {
        let grpObj;
        if (id) {
            grpObj = await graphClient!.api(id)
                .get();
        } else {
            grpObj = await graphClient!.api('/groups')
                .filter(`displayName eq '${name}'`)
                .get();
        }
        grp = grpObj.value[0];
    } catch { }

    return grp;
}

export const findGroup = async (authProvider: AuthCodeMSALBrowserAuthenticationProvider, startsWith: string, select?: string): Promise<Group[]> => {
    ensureClient(authProvider);
    const selectStr = select || 'displayName,id,description,groupTypes,mailEnabled,securityEnabled';

    // Return all groups that start with the startsWith display name
    // 
    let grps: Group[] = [];
    try {
        const resp = await graphClient!.api('/groups')
            .filter(`startsWith(displayName, '${startsWith}')`)
            .select(selectStr)
            .get();

        grps = resp.value;
    } catch { }

    return grps;
}

export const findUser = async (authProvider: AuthCodeMSALBrowserAuthenticationProvider, startsWith: string, select?: string): Promise<User[]> => {
    ensureClient(authProvider);
    const selectStr = select || 'displayName,id,description,groupTypes,mailEnabled,securityEnabled';

    // Return all users that start with the startsWith surname/givenName/upn/deoartment/employeeId
    let users: User[] = [];
    try {
        const resp = await graphClient!.api('/users')
            .filter(
                `startsWith(userPrincipalName, '${startsWith}') 
                 or startsWith(surname, '${startsWith}') 
                 or startsWith(givenName, '${startsWith}') 
                 or startsWith(department, '${startsWith}') 
                 or startsWith(displayName, '${startsWith}') 
                 or startsWith(employeeId, '${startsWith}')`
            )
            .select(selectStr)
            .get();

        users = resp.value;
    } catch { }

    return users;
}

export const getCountDirectGroupMembers = async (authProvider: AuthCodeMSALBrowserAuthenticationProvider, id: string): Promise<number | undefined> => {
    ensureClient(authProvider);

    // Return count of transitive group members
    //
    let cmt: number | undefined = undefined;
    try {
        cmt = await graphClient!.api(`/groups/${id}/members/$count`)
            .header('ConsistencyLevel', 'eventual')
            .get();
    } catch { }

    return cmt;
}

export const getOrganization = async (authProvider: AuthCodeMSALBrowserAuthenticationProvider): Promise<Organization> => {
    ensureClient(authProvider);

    // Return organization the current user is logged in
    //
    const orgs = await graphClient!.api('/organization')
        // Only retrieve the specific fields needed
        .select('id, displayName, verifiedDomains')
        .get();

    // an array with one organization is returned  by graph api
    return orgs.value[0];
}

export const getBannerLogo = async (authProvider: AuthCodeMSALBrowserAuthenticationProvider, id: string): Promise<string | undefined> => {
    ensureClient(authProvider);

    // Return default banner logo
    // 
    let logoUrl: string | undefined = undefined;
    try {
        const logo = await graphClient!.api(`/organization/${id}/branding/localizations/default/bannerLogo`)
            .get();
        logoUrl = URL.createObjectURL(logo);
    } catch { }

    return logoUrl;
}

export const getHealthOverview = async (authProvider: AuthCodeMSALBrowserAuthenticationProvider): Promise<ServiceHealth[]> => {
    ensureClient(authProvider);

    // Return service health overview
    //
    let sh: ServiceHealth[] = [];
    try {
        const health = await graphClient!.api('/admin/serviceAnnouncement/healthOverviews')
        .get();
        sh = health.value;
    } catch { }
    
    return sh;
}

export const getUserAccountsByIds = async (authProvider: AuthCodeMSALBrowserAuthenticationProvider, selectStr: string,
    ids: string[], passwords: string[] | undefined, progress: (done: boolean, maxCount: number, count: number) => void): Promise<User[]> => {

    ensureClient(authProvider);

    // Return the user accounts specified by the user id
    //
    let accounts: User[] = [];
    const maxCount = ids.length;
    for (let count = 0; count < ids.length; ++count) {
        const ac = await getUser(authProvider!, ids[count], selectStr);
        if (ac) {
            if (passwords) {
                const pwdProfile: PasswordProfile = {
                    password: passwords[count]
                };
                ac.passwordProfile = pwdProfile;
            }
            if (ac.userPrincipalName) {
                const mo = await getMemberOf(authProvider, ac.userPrincipalName);
                ac.memberOf = mo;
            }
            accounts.push(ac);
        }
        progress(false, maxCount, count + 1);
    }
    return accounts;
}

export const getUserAccountsByCategory = async (authProvider: AuthCodeMSALBrowserAuthenticationProvider, category: string,
    includeGroups: boolean, select: string | null,
    progress: (done: boolean, maxCount: number, count: number) => void): Promise<User[] | undefined> => {

    ensureClient(authProvider);

    // Return user accounts of the specified category
    //
    let accounts: User[] = [];
    const cat = getCategory(category);
    let selectStr = cat?.exportSelect.join(',');
    if (select) {
        selectStr = select;
    }
    if (includeGroups) {
        selectStr += ',memberOf';
    }
    const maxCount = await getCountDirectGroupMembers(authProvider, cat?.mainGroup.id!);
    const response: PageCollection = await graphClient!.api(`groups/${cat?.mainGroup.id}/members`)
        .select(selectStr!)
        .get();

    let count = 0;
    const rds: Promise<void>[] = [];
    let callback: PageIteratorCallback = (ua: User) => {
        const rd = async (ua: User) => {
            if (ua.userPrincipalName && includeGroups) {
                const mo = await getMemberOf(authProvider, ua.userPrincipalName);
                ua.memberOf = mo;
            }
            accounts.push(ua);
            progress(false, maxCount!, ++count);
        }
        rds.push(rd(ua));
        return true;
    };

    let pageIterator = new PageIterator(graphClient!, response, callback);
    while (!pageIterator.isComplete()) {
        await pageIterator.iterate();
        if (pageIterator.isComplete()) {
            await Promise.all(rds);
            progress(true, maxCount!, count);
            return accounts;
        }
    }
}

export const updateUsersSpecialProps = async (authProvider: AuthCodeMSALBrowserAuthenticationProvider, category: string,
    progress: (category: string, done: boolean, maxCount: number, count: number) => void) => {

    ensureClient(authProvider);

    // Update special props on all acounts of this category.
    //
    const cat = getCategory(category);
    if (!cat?.specialProperties)
        return;

    const maxCount = await getCountDirectGroupMembers(authProvider, cat?.mainGroup.id!);
    const specPropsSelArr = cat.specialProperties.map((p) => {
        return Object.keys(p)[0];
    });
    const response: PageCollection = await graphClient!.api(`groups/${cat?.mainGroup.id}/members`)
        .select('userPrincipalName,' + specPropsSelArr.join(','))
        .get();

    let count = 0;
    const rds: Promise<void>[] = [];
    let callback: PageIteratorCallback = (ua: User) => {
        const rd = async (ua: User) => {
            if (ua.userPrincipalName) {
                for (const sp of cat.specialProperties) {
                    await updateUser(authProvider, ua.userPrincipalName, sp);
                }
            }
            progress(category, false, maxCount!, ++count);
        }
        rds.push(rd(ua));
        return true;
    };

    let pageIterator = new PageIterator(graphClient!, response, callback);
    while (!pageIterator.isComplete()) {
        await pageIterator.iterate();
        if (pageIterator.isComplete()) {
            await Promise.all(rds);
            progress(category, true, maxCount!, count);
        }
    }
}

export const getUser = async (authProvider: AuthCodeMSALBrowserAuthenticationProvider, upnOrId: string, selectStr: string): Promise<User | undefined> => {
    ensureClient(authProvider);

    // Return the User object for the specified upn (user principle name)
    //
    let user: User | undefined;
    try {
        user = await graphClient!.api(`/users/${upnOrId}`)
            .select(selectStr)
            .get();
    } catch { }

    return user;
}

export const getUsersByProp = async (authProvider: AuthCodeMSALBrowserAuthenticationProvider, property: string, value: string, selectStr: string): Promise<User[] | undefined> => {
    ensureClient(authProvider);

    // Return the User objects for the specified property value
    //
    let users: User[] | undefined;
    try {
        const resp = await graphClient!.api('/users')
            .header('ConsistencyLevel', 'eventual')
            .filter(`${property} eq '${value}'`)
            .select(selectStr)
            .get();
        users = resp.value;
    } catch { }

    return users;
}

export const isUserExists = async (authProvider: AuthCodeMSALBrowserAuthenticationProvider, property: string, value: string): Promise<boolean> => {
    ensureClient(authProvider);

    // Return the User object for the specified upn (user principle name)
    //
    let isExists = false;
    try {
        const resp = await graphClient!.api('/users')
            .header('ConsistencyLevel', 'eventual')
            .filter(`${property} eq '${value}'`)
            .select(`id,${property}`)
            .count(true)
            .get();
        isExists = resp['@odata.count'] > 0 && resp.value && resp.value[0] && (resp.value[0][property] as string).length > 0;
    } catch (e) {
        console.log('error', e);
    }

    return isExists;
}

export const getMemberOf = async (authProvider: AuthCodeMSALBrowserAuthenticationProvider, upn: string): Promise<Group[] | undefined> => {
    ensureClient(authProvider);

    // Return the groups a user is direct member of.
    //
    let memberOf: Group[] | undefined;
    try {
        const groups = await graphClient!.api(`/users/${upn}/memberOf`)
            .select('displayName,id,groupTypes')
            .get();
        memberOf = groups.value;
    } catch { }

    return memberOf;
}

export const updateUser = async (authProvider: AuthCodeMSALBrowserAuthenticationProvider, upn: string, userUpdProps: Record<string, string | null>): Promise<boolean> => {
    ensureClient(authProvider);

    // Update the user's properties
    //
    let success = true;
    try {
        await graphClient!.api(`/users/${upn}`)
            .update(userUpdProps)
    } catch {
        success = false;
    }

    return success;
}

export const addMemberToGroup = async (authProvider: AuthCodeMSALBrowserAuthenticationProvider, memberId: string, groupId: string): Promise<boolean> => {
    ensureClient(authProvider);

    // Add a membert to a group
    //
    let success = true;
    const directoryObject = {
        '@odata.id': `https://graph.microsoft.com/v1.0/directoryObjects/${memberId}`
    };
    try {
        await graphClient!.api(`/groups/${groupId}/members/$ref`)
            .post(directoryObject);
    } catch (err) {
        console.log(err);
        success = false;
    }

    return success;
}

export const removeMemberFromGroup = async (authProvider: AuthCodeMSALBrowserAuthenticationProvider, memberId: string, groupId: string): Promise<boolean> => {
    ensureClient(authProvider);

    // Remove a member from a group
    //
    let success = true;
    try {
        await graphClient!.api(`/groups/${groupId}/members/${memberId}/$ref`)
            .delete();
    } catch (err) {
        console.log(err);
        success = false;
    }

    return success;
}

export interface CreateUserError {
    code: string;       // e.g. ObjectConflict
    target: string;     // e.g. userPrincipalName
    message: string;
}

export const createUser = async (authProvider: AuthCodeMSALBrowserAuthenticationProvider, userProps: Record<string, any>): Promise<CreateUserError> => {
    ensureClient(authProvider);

    // Create a new user account
    //
    let error: CreateUserError = null as unknown as CreateUserError;
    try {
        await graphClient!.api(`/users`)
            .post(userProps)
    } catch (err) {
        const ge = err as GraphError;
        try {
            const body = JSON.parse(ge.body);
            const details = body.details ? body.details[0] : null;
            error = {
                code: details ? details.code : ge.code,
                target: details ? details.target : null,
                message: details ? details.message : ge.message
            }
        } catch {
            error = {
                code: ge.code || '',
                target: '',
                message: ge.message
            }
        }
    }

    return error;
}

export const getDepartments = async (authProvider: AuthCodeMSALBrowserAuthenticationProvider, startsWith?: string) => {
    ensureClient(authProvider);

    // Returns a list of departments that starts with the specified string
    //
    // const cat = getCategory(category);
    let response: PageCollection | undefined = undefined;
    if (startsWith) {
        // response = await graphClient!.api(`groups/${cat?.mainGroup.id}/members`)
        response = await graphClient!.api('/users')
            .header('ConsistencyLevel', 'eventual')
            .count(true)
            .select('department')
            .filter(`startswith(department, '${startsWith}')`)
            .get();
    } else {
        // response = await graphClient!.api(`groups/${cat?.mainGroup.id}/members`)
        response = await graphClient!.api('/users')
            .header('ConsistencyLevel', 'eventual')
            .count(true)
            .select('department')
            .get();
    }

    let departments: string[] = [];
    let callback: PageIteratorCallback = (ua: User) => {
        if (ua.department) {
            if (!departments.includes(ua.department)) {
                departments.push(ua.department)
            }
        }
        return true;
    };

    let pageIterator = new PageIterator(graphClient!, response!, callback);
    while (!pageIterator.isComplete()) {
        await pageIterator.iterate();
        if (pageIterator.isComplete()) {
            return departments;
        }
    }
}

export const deleteUser = async (authProvider: AuthCodeMSALBrowserAuthenticationProvider, upn: string) => {
    ensureClient(authProvider);

    // Delete the user specified by the user principal name (upn)
    // 
    let success = true;
    try {
        await graphClient!.api(`/users/${upn}`)
            .delete();
    } catch {
        success = false;
    }

    return success;
}

export const getAppRoles = async (authProvider: AuthCodeMSALBrowserAuthenticationProvider) => {
    ensureClient(authProvider);

    // Get app roles and if claimed by currently logged in user
    // 
    let appRoles: { value: string, id: string, claimed: boolean }[] = [];
    try {
        const appId = config.appId;
        const applications = await graphClient!.api('/applications')
            .header('ConsistencyLevel', 'eventual')
            .filter(`appId eq '${appId}'`)
            .get();
        const appName = applications.value[0].displayName;
        appRoles = applications.value[0]['appRoles'].map((r: { value: string; id: string; }) => {
            return { 'value': r.value, 'id': r.id, 'claimed': false }
        });

        const appRoleAssignments = await graphClient!.api('/me/appRoleAssignments')
            .get();
        for (const ara of appRoleAssignments.value) {
            if (ara.resourceDisplayName === appName) {
                for (let idx = 0; idx < appRoles.length; ++idx) {
                    if (appRoles[idx].id === ara.appRoleId) {
                        appRoles[idx].claimed = true;
                    }
                }
            }
        }
    } catch (err) {
        console.log(err);
    }

    return appRoles;
}

export const resetPassword = async (authProvider: AuthCodeMSALBrowserAuthenticationProvider, upn: string) => {
    ensureClient(authProvider);

    // Reset a users password
    // 
    let newPassword = undefined;
    try {
        const response = await graphClient!.api(`/users/${upn}/authentication/passwordMethods/28c10230-6103-485e-b985-444c60001490/resetPassword`)
            .post(undefined);
        if (response) {
            newPassword = response.newPassword;
        }
    } catch (err) {
        console.log(err);
    }

    return newPassword;
}

export interface SetNewPasswordError {
    code: string;       // e.g. Request_BadRequest
    message: string;
}

export const setNewPassword = async (authProvider: AuthCodeMSALBrowserAuthenticationProvider, upn: string, newPassword: string, forceChangePasswordNextSignIn: boolean) => {
    ensureClient(authProvider);

    // Set a user's password 
    // 

    const passwordProfile = {
        passwordProfile: {
            forceChangePasswordNextSignIn: forceChangePasswordNextSignIn,
            password: newPassword
        }
    };

    let error: SetNewPasswordError = null as unknown as CreateUserError;
    try {
        await graphClient!.api(`/users/${upn}`)
            .update(passwordProfile);
    } catch (err) {
        const ge = err as GraphError;
        try {
            const body = JSON.parse(ge.body);
            const details = body.details ? body.details[0] : null;
            error = {
                code: details ? details.code : ge.code,
                message: details ? details.message : ge.message
            }
        } catch {
            error = {
                code: ge.code || '',
                message: ge.message
            }
        }
    }
    return error;
}